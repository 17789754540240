body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #081E3D;
  color: #ffffff;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

header {
  background-color: #081E3D;
  color: white;
  padding: 1.5em;
  text-align: center;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

header h1 {
  margin: 0;
  font-size: 2.5em;
}

header .logo {
  width: 150px;
  margin-bottom: 1em;
}

main {
  padding: 2em;
  text-align: center;
  flex: 1;
}

main p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #ffffff;
}

main a {
  text-decoration: none;
}

main button {
  margin: 0.5em;
  padding: 0.5em 1em;
  font-size: 1em;
  color: #081E3D;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

main button:hover {
  background-color: #f0f0f0;
}

footer {
  background-color: #081E3D;
  color: white;
  padding: 1em;
  text-align: center;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

footer p {
  margin: 0;
  font-size: 1em;
}

.ErrorPage {
  text-align: center;
  padding: 2em;
}

.ErrorPage h1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.ErrorPage p {
  font-size: 1.2em;
  line-height: 1.6;
}

#loading-overlay {
  position: fixed; /* Cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 9999; /* Ensure it's on top */
}

.loader { /* Styles for the loading circle */
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite; /* Animate the circle */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}